import { useContext } from 'react';
import { PermissionContext } from './PermissionContext';
import { PermissionProps } from './PermissionProps';

export function Restricted(props: PermissionProps) {
  const { permissions, updatePermissions } = useContext(PermissionContext);
  updatePermissions();
  if (permissions.includes('systemadmin')) {
    return props.children;
  }

  if (permissions.some((perm) => props.permissions.includes(perm))) {
    return props.children;
  } else {
    return null;
  }
}

export default Restricted;
