import axios, { AxiosResponse } from 'axios';
import { FC, useState, createContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';

type PermissionContextType = {
  permissions: string[];
  updatePermissions: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionContext = createContext<PermissionContextType>(
  {} as PermissionContextType
);

export const PermissionContextProvider: FC = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loadPermission, setLoadPermission] = useState<boolean>(true);
  const { authState } = useOktaAuth();

  const updatePermissions = () => {
    if (
      authState != null &&
      authState.isAuthenticated &&
      loadPermission &&
      (permissions == null || permissions.length == 0)
    ) {
      axios
        .get<string[]>('/api/v1/permission/userpermission')
        .then((response: AxiosResponse) => {
          var data = response.data;
          if (data.length == 0) {
            setLoadPermission(false);
          }
          setPermissions(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <PermissionContext.Provider value={{ permissions, updatePermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};
