import { useNavigate } from 'react-router-dom';
import Router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import '@progress/kendo-theme-material/dist/all.css';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, useOktaAuth } from '@okta/okta-react';
import { createContext, Suspense, useEffect, useState } from 'react';
import { FallbackView } from './FallbackView';
import setupAxios from 'src/config/SetupAxios';
import axios, { AxiosResponse } from 'axios';
import './custom-style.css';
import  { PermissionContextProvider } from './permission/PermissionContext';

declare global {
  interface Window {
    BASE_URL: string;
    OKTA_ISSUER: string;
    OKTA_CLIENTID: string;
  }
}

const config = {
  clientId: window.OKTA_CLIENTID,
  issuer: window.OKTA_ISSUER,
  redirectUri: `${window.location.origin.toString()}/app/callback`,
  scopes: ['openid', 'profile', 'email'],
  PostLogoutRedirectUri: window.location.origin.toString(),
  pkce: true
};

const oktaAuth = new OktaAuth(config);

setupAxios(axios);

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(
      toRelativeUrl(originalUri || '/app/dashboard', window.location.origin)
    );
  };

  return (
    <Suspense fallback={<FallbackView />}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <PermissionContextProvider>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </PermissionContextProvider>
      </Security>
    </Suspense>
  );
}
export default App;
