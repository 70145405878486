import * as React from 'react';
import { toODataString } from '@progress/kendo-data-query';
import axios, { AxiosResponse } from 'axios';
import { Trade } from 'src/models/trade';
import SuspenseLoader from 'src/components/SuspenseLoader';

export const TradeLoader = (props) => {
  const baseUrl = `/odata/trades?$orderby=TradeDate desc, CreatedDate desc&$count=true&`;
  const init = {
    method: 'GET',
    accept: 'application/json',
    headers: {}
  };
  const lastSuccess = React.useRef('');
  const pending = React.useRef('');

  const requestDataIfNeeded = () => {
    if (
      pending.current ||
      toODataString(props.dataState) === lastSuccess.current
    ) {
      return;
    }

    pending.current = toODataString(props.dataState);

    axios
      .get(baseUrl + pending.current, init)
      .then((response: AxiosResponse) => {
        lastSuccess.current = pending.current;
        pending.current = '';

        const data: Array<Trade> = (response.data.value || []).map((trade) => {
          let clientSellCcy = props.currencies.find(
            (x) => x.Code === trade.ClientSellCurrencyCode
          );
          let clientBuyCcy = props.currencies.find(
            (x) => x.Code === trade.ClientBuyCurrencyCode
          );
          return {
            ...trade,
            TradeDate: trade.TradeDate ? new Date(trade.TradeDate) : null,
            ValueDate: trade.ValueDate ? new Date(trade.ValueDate) : null,
            DateCreated: trade.DateCreated ? new Date(trade.DateCreated) : null,
            DateModified: trade.DateModified
              ? new Date(trade.DateModified)
              : null,
            Rate: trade.Rate?.toFixed(clientBuyCcy?.RatePrecision ?? 2) ?? 0,
            SellAmountPlusCcy: `${trade.ClientSellAmount.toFixed(
              clientSellCcy?.AmountPrecision ?? 2
            )} ${trade.ClientSellCurrencyCode}`,
            BuyAmountPlusCcy: `${trade.ClientBuyAmount.toFixed(
              clientBuyCcy?.AmountPrecision ?? 2
            )} ${trade.ClientBuyCurrencyCode}`,
            BeneficiaryName:
              trade.PaymentInstructions != null &&
              trade.PaymentInstructions.length
                ? trade.PaymentInstructions[0].BeneficiaryName
                : '',
            BeneficiaryCountry:
              trade.PaymentInstructions != null &&
              trade.PaymentInstructions.length
                ? trade.PaymentInstructions[0].BeneficiaryCountry
                : '',
            TradeDateStr: trade.TradeDate
              ? new Date(trade.TradeDate).toLocaleDateString()
              : '',
            ValueDateStr: trade.ValueDate
              ? new Date(trade.ValueDate).toLocaleDateString()
              : ''
          } as Trade;
        });
        if (toODataString(props.dataState) === lastSuccess.current) {
          props.onDataReceived.call(undefined, {
            data: data,
            total: response.data['@odata.count']
          });
        } else {
          requestDataIfNeeded();
        }
      });
  };

  requestDataIfNeeded();
  return pending.current ? <SuspenseLoader /> : null;
};
