import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Footer from 'src/components/Footer';
import { Trade } from 'src/models/trade';
import { CfgCurrency } from 'src/models/cfg-currency';
import { TradeLoader } from './trade-loader';
import Restricted from 'src/permission/Restricted';

function TradeBlotter() {
  const [trades, setTrades] = useState<Array<Trade>>([]);
  const [currencies, setCurrencies] = useState<Array<CfgCurrency>>([]);
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0
  });
  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (trades) => {
    setTrades(trades);
  };

  useEffect(() => {
    axios
      .get('/odata/currency')
      .then((response: AxiosResponse) => {
        const currencies: Array<CfgCurrency> = response.data.value;
        setCurrencies(currencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Restricted permissions={['Trade_Read']}>
        <div>
          <Helmet>
            <title>Connect Admin - Trade Blotter</title>
          </Helmet>
          <Typography
            sx={{ mb: 0, p: 2 }}
            variant="h3"
            component="h3"
            gutterBottom
          >
            Trade Blotter
          </Typography>
          <>
            <Grid
              filterable={false}
              sortable={false}
              pageable={true}
              {...dataState}
              data={trades}
              onDataStateChange={dataStateChange}
              className="overflow-y-auto"
            >
              <GridColumn
                field="PayId"
                title="Pay Id"
                editable={false}
                width={275}
              />
              <GridColumn
                field="TradeDateStr"
                title="Trade Date"
                editable={false}
                width={250}
              />
              <GridColumn
                field="TradeStatus"
                title="Trade Status"
                editable={false}
                width={150}
              />
              <GridColumn
                field="SellAmountPlusCcy"
                title="Client Sell Amount"
                editable={false}
                width={250}
              />
              <GridColumn
                field="BuyAmountPlusCcy"
                title="Client Buy Amount"
                editable={false}
                width={250}
              />
              <GridColumn
                field="Rate"
                title="Rate"
                editable={false}
                width={100}
              />
              <GridColumn
                field="ValueDateStr"
                title="Value Date"
                editable={false}
                width={250}
              />
              <GridColumn
                field="BeneficiaryName"
                title="Beneficiary Name"
                editable={false}
                width={250}
              />
              <GridColumn
                field="BeneficiaryCountry"
                title="Beneficiary Country"
                editable={false}
                width={200}
              />
            </Grid>
            {currencies.length > 0 && (
              <TradeLoader
                dataState={dataState}
                currencies={currencies}
                onDataReceived={dataReceived}
              />
            )}
          </>
        </div>
      </Restricted>
      <Footer />
    </>
  );
}

export default TradeBlotter;
