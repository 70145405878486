import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject, useRoutes } from 'react-router';
import { LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import TradeBlotter from './content/trade-blotter';
import AuthGuard from './AuthGaurd';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Overview = Loader(lazy(() => import('src/content/overview')));

const DashboardOverview = Loader(
  lazy(() => import('src/content/dashboards/Overview'))
);
const SignUps = Loader(lazy(() => import('src/content/company')));
const BankBicCode = Loader(lazy(() => import('src/content/biccode')));
const RoleManagement = Loader(lazy(() => import('src/content/role-management')));
const OnBoarding = Loader(lazy(() => import('src/content/onboarding')));
const CurrencyRuleUpload = Loader(
  lazy(() => import('src/content/currency-rule'))
);
const CountryCurrency = Loader(
  lazy(() => import('src/content/country-currency'))
);
const CurrencyRule = Loader(lazy(() => import('src/content/currency-rule')));
const Clients = Loader(lazy(() => import('src/content/clients')));
const CompanyDetail = Loader(lazy(() => import('src/content/company-detail')));
const Entity = Loader(lazy(() => import('src/content/entity')));
const CountryEntity = Loader(lazy(() => import('src/content/country-entity')));
const BeneTemplateValidation = Loader(
  lazy(() => import('src/content/bene-template-validation'))
);
const CurrencyFee = Loader(lazy(() => import('src/content/currency-fee')));

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

export default function Router() {
  const { authState } = useOktaAuth();

  let routes: RouteObject[] = [
    {
      path: '',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <Overview />
        },
        {
          path: 'app/callback',
          element: <LoginCallback />
        },
        {
          path: 'status',
          children: [
            {
              path: '404',
              element: <Navigate to={'/'} />
            },
            {
              path: '500',
              element: <Status500 />
            },
            {
              path: 'maintenance',
              element: <StatusMaintenance />
            },
            {
              path: 'coming-soon',
              element: <StatusComingSoon />
            }
          ]
        },
        {
          path: '*',
          element: <Navigate to={'/'} />
        }
      ]
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <SidebarLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardOverview />
        },
        {
          path: 'signups',
          element: <SignUps />
        },
        {
          path: 'clients',
          element: <Clients />
        },
        {
          path: 'bic-bank-codes',
          element: <BankBicCode />
        },
        {
          path: 'role-management',
          element: <RoleManagement />
        },
        {
          path: 'signup/:id',
          element: <OnBoarding />
        },
        {
          path: 'client/:id',
          element: <CompanyDetail />
        },
        {
          path: 'currency-rule-upload',
          element: <CurrencyRuleUpload />
        },
        {
          path: 'currency-country',
          element: <CountryCurrency />
        },
        {
          path: 'currency-rule',
          element: <CurrencyRule />
        },
        {
          path: 'entity',
          element: <Entity />
        },
        {
          path: 'country-entity',
          element: <CountryEntity />
        },
        {
          path: 'bene-template-validation',
          element: <BeneTemplateValidation />
        },
        {
          path: 'trade-blotter',
          element: <TradeBlotter />
        },
        {
          path: 'currency-fee',
          element: <CurrencyFee />
        },
        {
          path: '*',
          element: <Navigate to={'/'} />
        }
      ]
    }
  ];

  return useRoutes(routes);
}
