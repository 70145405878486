import { AxiosStatic } from 'axios';

export default function setupAxios(axios: AxiosStatic) {
  axios.defaults.baseURL = window.BASE_URL;
  axios.interceptors.request.use(
    (config) => {
      const OktaTokenStorageEntry =
        localStorage.getItem('okta-token-storage') || null;

      if (!OktaTokenStorageEntry) {
        return config;
      }
      const oktaTokenStorage = JSON.parse(OktaTokenStorageEntry);
      if (oktaTokenStorage && oktaTokenStorage.accessToken) {
        config.headers.Authorization = `Bearer ${oktaTokenStorage.accessToken.accessToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
}
