import { useContext, useEffect, useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import PriceCheckRoundedIcon from '@mui/icons-material/PriceCheckRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import JoinFullRoundedIcon from '@mui/icons-material/JoinFullRounded';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SellIcon from '@mui/icons-material/Sell';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Addchart } from '@mui/icons-material';
import Restricted from 'src/permission/Restricted';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      .MuiListItem-root {
        padding: 1px 0;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};
          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }
          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
        &.Mui-children {
          flex-direction: column;
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }
        .MuiCollapse-root {
          width: 100%;
          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }
          .MuiListItem-root {
            padding: 1px 0;
            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};
              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }
              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/app/dashboard"
                  startIcon={<DashboardIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <Restricted permissions={['Role_Read']}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Admin
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <Restricted permissions={['Role_Read']}>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/role-management"
                      startIcon={<AdminPanelSettingsIcon />}
                    >
                      Role Management
                    </Button>
                  </ListItem>
                </List>
              </Restricted>
            </SubMenuWrapper>
          </List>
        </Restricted>
        <Restricted permissions={['BicCode_Read']}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                General
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <Restricted permissions={['BicCode_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/bic-bank-codes"
                      startIcon={<ListAltIcon />}
                    >
                      BIC / Bank Codes
                    </Button>
                  </ListItem>
                </Restricted>
              </List>
            </SubMenuWrapper>
          </List>
        </Restricted>
        <Restricted
          permissions={[
            'CountryCurrency_Read',
            'CurrencyRule_Read',
            'Errors_Read',
            'FxEntity_Read'
          ]}
        >
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Operations
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <Restricted permissions={['DeliveryInstruction_Validate']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/bene-template-validation"
                      startIcon={<AssignmentTurnedInIcon />}
                    >
                      Bene Template Validation
                    </Button>
                  </ListItem>
                </Restricted>
                <Restricted permissions={['CountryCurrency_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/currency-country"
                      startIcon={<PriceCheckRoundedIcon />}
                    >
                      Currency Country
                    </Button>
                  </ListItem>
                </Restricted>

                <Restricted permissions={['CurrencyRule_Read', 'Errors_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/currency-rule"
                      startIcon={<RuleFolderOutlinedIcon />}
                    >
                      Currency Rules
                    </Button>
                  </ListItem>
                </Restricted>

                <Restricted permissions={['FxEntity_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/entity"
                      startIcon={<BusinessRoundedIcon />}
                    >
                      Entity
                    </Button>
                  </ListItem>
                </Restricted>
              </List>
            </SubMenuWrapper>
          </List>
        </Restricted>
        <Restricted permissions={['CurrencyFee_Read', 'FxEntity_Read']}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Configuration
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <Restricted permissions={['FxEntity_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/country-entity"
                      startIcon={<JoinFullRoundedIcon />}
                    >
                      Country Mapping
                    </Button>
                  </ListItem>
                </Restricted>

                <Restricted permissions={['CurrencyFee_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/currency-fee"
                      startIcon={<SellIcon />}
                    >
                      Currency Fees
                    </Button>
                  </ListItem>
                </Restricted>
              </List>
            </SubMenuWrapper>
          </List>
        </Restricted>
        <Restricted permissions={['Trade_Read']}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Payments
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <Restricted permissions={['Trade_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/trade-blotter"
                      startIcon={<Addchart />}
                    >
                      Trade Blotter
                    </Button>
                  </ListItem>
                </Restricted>
              </List>
            </SubMenuWrapper>
          </List>
        </Restricted>
        <Restricted permissions={['Company_Read']}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Sales
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <Restricted permissions={['Company_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/clients"
                      startIcon={<SupervisedUserCircleIcon />}
                    >
                      Clients
                    </Button>
                  </ListItem>
                </Restricted>

                <Restricted permissions={['Company_Read']}>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/app/signups"
                      startIcon={<SupervisedUserCircleOutlinedIcon />}
                    >
                      Sign Ups
                    </Button>
                  </ListItem>
                </Restricted>
              </List>
            </SubMenuWrapper>
          </List>
        </Restricted>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
