import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthGaurdProps } from './AuthGaurdProps';
/* eslint-disable-next-line */

export function AuthGuard(props: AuthGaurdProps) {
  const { oktaAuth, authState } = useOktaAuth();
  if (!authState) return null;
  if (!authState?.isAuthenticated) {
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin
    );
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();

    return null;
  }
  return props.children;
}

export default AuthGuard;
